import React from 'react'
import PropTypes from 'prop-types'

import bg1 from '../images/bg-1.jpg'
import podia from '../images/podia.png'
import vidyardHalloween from '../images/vidyard-halloween.jpg'
import vidyardCta from '../images/vidyard-cta.jpg'
import freshly from '../images/freshly.png'
import freshlyPromo from '../images/freshly-promo.png'
import glassbox from '../images/glassbox.png'
import AsteroidX from '../images/projects/asteroidx.png'
import AsteroidIndex from '../images/projects/asteroidindex.png'
import PlayerApi from '../images/projects/player-api.png'
import ProjectGrapesJS from '../images/projects/project-grapesjs.png'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Basil</h2>
          <p>
            Basil is a systems integrator at heart, who is passionate about
            empowering teams and organizations through development of
            integrations, automations and education in technology.
          </p>
          <span className="image main">
            <img src={bg1} alt="" />
          </span>
          <p>
            This passion to empower teams has driven him to leverage his {new Date().getFullYear() - 2016}+
            years of software development experience to solve customer
            challenges with a focus on B2B Software as a Service (SaaS)
            products. Basil has worked in multiple freelancing and commercial
            projects and helped build products for clients and
            organizations all over the world. Through
            his various opportunities at startups to the public sector to a
            Fortune 500 company, Basil has
            experience in product development, data analysis, and solutions
            engineering. He leverages his analytical, critical thinking and
            management skills not only when developing software, but also while
            consulting clients on business software solutions and applications.
          </p>
          <p>
            Basil is a tech enthusiast and has a keen interest in startups and
            growth hacking. In his free time, he enjoys attending tech
            conferences and meetups, reading, mountain biking, snowboarding, travelling
            and learning Muay Thai and other survival techniques.
          </p>
          {close}
        </article>

        <article
          id="project"
          className={`${this.props.article === 'project' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Recent Projects</h2>
          <ol>
          <li>
              <h3>Freshly: Batch & Expiry</h3>
              <p>
                Freshly is a Shopify app that helps with batch and expiry date tracking and makes it easy to sell perishables such as food & beverages, pharmaceuticals, and cosmetics, on Shopify.
              </p>
              <span className="image main">
                <img src={freshly} alt="" />
              </span>
              <ul>
                <li>
                  Technologies: Ruby on Rails + PostgreSQL, Sidekiq, Heroku, Polaris
                </li>
                <li>Client(s): Shopify merchants with perishables products</li>
                <li>
                  Project Status: Ongoing SaaS development with user testing
                </li>
              </ul>
              <h4>
                <a
                  href="https://apps.shopify.com/freshly-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Project <i className="fa fa-arrow-right" />
                </a>
              </h4>
            </li>
            <li>
              <h3>Glassbox Scripts</h3>
              <p>
                Glassbox Scripts is a workflow and task automation tool built
                for teams with a focus on customizability
                and versatility.
              </p>
              <span className="image main">
                <img src={glassbox} alt="" />
              </span>
              <ul>
                <li>
                  Technologies: Ruby on Rails + PostgreSQL, Sidekiq, Heroku
                </li>
                <li>Client(s): Various customer facing technical roles</li>
                <li>
                  Project Status: Ongoing SaaS development with user testing
                </li>
              </ul>
              <h4>
                <a
                  href="https://www.glassbox.app/scripts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Project <i className="fa fa-arrow-right" />
                </a>
              </h4>
            </li>
            <li>
              <h3>AsteroidX Dashboard</h3>
              <p>
                AsteroidX is an internal account management and a self-serve
                product offerring for Amazon PPC Ad performance monitoring,
                reporting and optimizations
              </p>
              <span className="image main">
                <img src={AsteroidX} alt="" />
              </span>
              <ul>
                <li>
                  Technologies: Ruby on Rails + PostgreSQL, Delayed Job, Heroku
                </li>
                <li>Client(s): Internal and Various Amazon sellers</li>
                <li>
                  Project Status: Ongoing SaaS development with user testing
                </li>
              </ul>
            </li>
            <li>
              <h3>AsteroidIndex</h3>
              <p>
                Asteroid Index is powerful software that helps Amazon sellers
                pick the right product idea by evaluating 1000s of data points:
                sales, BSR, reviews, trends, competitive listings, and beyond
              </p>
              <span className="image main">
                <img src={AsteroidIndex} alt="" />
              </span>
              <ul>
                <li>
                  Technologies: Ruby on Rails + PostgreSQL, Sucker Punch, Heroku
                </li>
                <li>Client(s): Various Amazon sellers</li>
              </ul>
            </li>
            <li>
              <h3>
                Vidyard Client-side integrations via JavaScript Player API
              </h3>
              <p>
                Design and functionality enhancements using Vidyard's JavaScript
                Player API and, integrations with third party client side
                analytics APIs
              </p>
              <span className="image main">
                <img src={PlayerApi} alt="" />
              </span>
              <ul>
                <li>Technologies: React.js, Vue.js, jQuery</li>
                <li>Client(s): Various</li>
                <li>Project Status: Completed</li>
              </ul>
              <h4>
                <a
                  href="https://watch.basilkhan.ca/watch/2DRxX88aVKeMZnuXcbcv82/playlist/two-column"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Sample Project <i className="fa fa-arrow-right" />
                </a>
              </h4>
            </li>
            <li>
              <h3>Video Sharing Page Designer</h3>
              <p>
                Self-serve page designer in an independent Rails app to design
                and export HTML, CSS code for Vidyard sharing pages
              </p>
              <span className="image main">
                <img src={ProjectGrapesJS} alt="" />
              </span>
              <ul>
                <li>Technologies: Rails, Grapes.js </li>
                <li>Client(s): Internal</li>
                <li>Project Status: User Testing</li>
              </ul>
            </li>
          </ol>
          {close}
          <div className="project">
            <h3>
              <a
                href="https://basilkhan-portfolio.herokuapp.com/portfolio.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Project Archives ...
              </a>
            </h3>
          </div>
        </article>
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work Experience</h2>
          <div className="work-experience">
            <h3>Freshly Commerce | Waterloo, ON Canada</h3>
            <h4>Co-founder | May 2021 - present</h4>
          </div>
          <span className="image main">
            <img src={freshlyPromo} alt="" />
          </span>
          <div className="work-experience">
            <h3>Podia | Remote 🌎</h3>
            <h4>Senior Product Developer | April 2019 - October 2021</h4>
          </div>
          <span className="image main">
            <img src={podia} alt="" />
          </span>
          <div className="work-experience">
            <h3>AsteroidX | Toronto, ON Canada</h3>
            <h4>Lead Software Developer | December 2018 - September 2019</h4>
            <span>
              <ul>
                <li>
                  Directed the development of the technology roadmap and the
                  movement of the core PPC (pay-per-click) services offerring to
                  an Amazon PPC self-serve product and an internal business
                  intelligence dashboard
                </li>
                <li>
                  Enabled internal leads and PPC team members to manage and
                  communicate to over 50 customer accounts by bringing
                  visibility into metrics such as ad performance, campaign
                  builds, PPC utilization, true Adverstising Cost of Sale (ACoS)
                  and account activity
                </li>
                <li>
                  Increased account management efficiency by over 5 times
                  through automation of bi-weekly internal customer success
                  workflows and technology touch points with customers through
                  background jobs for data synchonization, PDF reports and
                  emails
                </li>
                <li>
                  Developed an internal lead acquisition tool to efficiently
                  extract data into an internal Sales CRM platform with 40+
                  distributed background workers and smart prioritization of
                  background jobs
                </li>
              </ul>
            </span>
          </div>
          <div className="work-experience">
            <span className="image main">
              <img src={vidyardCta} alt="" />
            </span>
            <h3>Vidyard | Kitchener, ON Canada</h3>
            <h4>Client Services Developer | April 2017 - January 2019</h4>
            <span>
              <ul>
                <li>
                  Enhanced the core Vidyard product functionality by developing
                  proof of concepts and leading custom development and
                  integrations projects for mid-to-high profile clients
                </li>
                <li>
                  Accelerated time-to-value for customers with the development
                  of over 10 custom software applications and features to
                  integrate with customer internal workflows using Vidyard and
                  third party REST APIs
                </li>
                <li>
                  Collaborated with the core product management and engineering
                  teams to deliver product enhancements, features, video
                  migrations and client branding requirements for over 155
                  client projects within the Vidyard dashboard and its codebase
                  by integrating with the core product development workflow
                </li>
                <li>
                  Scoped out client’s technical requirements and custom
                  development projects while collaborating with Customer Success
                  and Account Management teams during both pre-sales and
                  post-sales stages
                </li>
              </ul>
            </span>
          </div>
          <span className="image main">
            <img src={vidyardHalloween} alt="" />
          </span>
          <div className="work-experience">
            <h3>LANSA | Toronto, ON Canada</h3>
            <h4>Application Developer | May 2016 - April 2017</h4>
            <span>
              <ul>
                <li>
                  Developed a Product Portfolio and Catalog search application
                  with existing customer data using React, Apache Solr indexing
                  engine for grouped and faceted searching and python to
                  automate preprocessing of unstructured data into a structured
                  NoSQL database with over 175 unique multi-attribute fields
                </li>
                <li>
                  Provided consultation and frontend development expertise to
                  integrate custom services using PHP and merchant’s payment
                  gateway API using Java for enterprise e-commerce clients on
                  the LANSA Commerce Edition Platform
                </li>
                <li>
                  Collaborated with pre-sales teams to develop proof of concepts
                  based on customer requirements for product features while
                  providing training for and leveraging off-shore development
                  teams
                </li>
              </ul>
            </span>
          </div>
          <div className="work-experience">
            <h3>
              <a
                href="https://www.linkedin.com/in/basilkh"
                target="_blank"
                rel="noopener noreferrer"
              >
                View More ...
              </a>
            </h3>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact Basil</h2>
          <form
            method="post"
            action="https://formspree.io/basil.khan@uwaterloo.ca"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" />
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/basilakhan?lang=en"
                className="icon fa-twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/basilkh"
                className="icon fa-linkedin"
              >
                <span
                  className="label"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/basilkhan05"
                className="icon fa-github"
              >
                <span
                  className="label"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
